.notification-box {
    background-color: #f9f9f9;
    border: 1px solid #e54c4c; /* Theme color */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
}

.notification-title {
    color: #e54c4c; /* Theme color */
    margin: 0 0 10px;
    font-size: 1.5rem;
}

.notification-body {
    color: #333;
    margin: 0;
    font-size: 1rem;
}

.adapt-button {
    background-color: white;
    color: #e54c4c;
    border: 2px solid #e54c4c;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    transition: all 0.3s ease;
}

.adapt-button:hover {
    background-color: #e54c4c;
    color: white;
}