.tracker-container {
  --primary: #e54c4c;
  --primary-light: #ff6b6b;
  --dark-bg: #121212;
  --dark-card: #1e1e1e;
  --dark-surface: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--dark-bg);
  font-family: "Poppins", sans-serif;
  color: var(--text-primary);

  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

.tracker-container .tracker-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 20px;
  background: var(--dark-surface);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
}

.tracker-container .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 12px;
  transition: var(--transition);
}

.tracker-container .logo:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.tracker-container .logo img {
  height: 32px;
  width: auto;
  filter: drop-shadow(0 2px 4px rgba(229, 76, 76, 0.2));
}

.tracker-container .logo h2 {
  background: linear-gradient(
    135deg,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5em;
  font-weight: 300;
  margin: 0;
  letter-spacing: 0.5px;
}

.tracker-container .status-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-secondary);
  padding: 8px 16px;
  background: var(--dark-surface);
  border-radius: 20px;
  transition: var(--transition);
}

.tracker-container .map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  filter: brightness(0.8) contrast(1.2);
}

.tracker-container .info-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(
    180deg,
    rgba(18, 18, 18, 0) 0%,
    rgba(18, 18, 18, 0.8) 20%,
    rgba(18, 18, 18, 0.95) 50%,
    rgba(18, 18, 18, 1) 100%
  );
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.tracker-container .cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  background: rgba(30, 30, 30, 0.7);
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: var(--transition);
}

.tracker-container .info-card {
  background: var(--dark-surface);
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.03);
}

.tracker-container .info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(229, 76, 76, 0.1);
}

.tracker-container .info-card h2 {
  font-size: 13px;
  margin: 0;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.tracker-container .info-card p {
  font-size: 24px;
  margin: 0;
  color: var(--text-primary);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tracker-container .eta p {
  background: linear-gradient(
    135deg,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.tracker-container .support-button {
  position: fixed;
  right: 20px;
  bottom: 200px;
  background: rgba(229, 76, 76, 0.9);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(229, 76, 76, 0.3);
  transition: var(--transition);
  z-index: 1000;
}

.tracker-container .support-button:hover {
  transform: translateY(-2px);
  background: rgba(229, 76, 76, 1);
  box-shadow: 0 6px 16px rgba(229, 76, 76, 0.4);
}

.tracker-container .support-button:active {
  transform: translateY(0);
}

.tracker-container .support-button svg {
  width: 20px;
  height: 20px;
}

.tracker-container .status-text.loading {
  position: relative;
  overflow: hidden;
}

.tracker-container .status-text.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  animation: loading 1.5s infinite;
}

/* Loading Animation */
@keyframes loading {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* Shimmer Effect */
.tracker-container .shimmer {
  position: relative;
  overflow: hidden;
  background: var(--dark-surface);
}

.tracker-container .shimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  animation: loading 1.5s infinite;
}

.tracker-container .rider-info {
  position: fixed;
  bottom: 180px; /* Position above metrics */
  left: 50%;
  transform: translateX(-50%);
  background: rgba(30, 30, 30, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1000;
  transition: var(--transition);
}

.tracker-container .rider-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.tracker-container .rider-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tracker-container .rider-name {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-primary);
}

.tracker-container .rider-status {
  font-size: 13px;
  color: var(--text-secondary);
}

.tracker-container .call-rider {
  background: rgba(229, 76, 76, 0.1);
  border: none;
  color: var(--primary);
  padding: 8px 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.tracker-container .call-rider:hover {
  background: rgba(229, 76, 76, 0.2);
  transform: translateY(-1px);
}

.tracker-container .call-rider svg {
  width: 16px;
  height: 16px;
}

.tracker-container .admin-info {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 400px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
}

.tracker-container .admin-card {
  background: rgba(30, 30, 30, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 16px;
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.tracker-container .admin-card h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 12px 0;
  color: var(--primary);
}

.tracker-container .admin-card p {
  margin: 0 0 12px 0;
  font-size: 14px;
  color: var(--text-secondary);
}

.tracker-container .location-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.tracker-container .location-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tracker-container .location-details {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
}

.tracker-container .location-details p {
  margin: 0 0 8px 0;
  font-size: 13px;
  line-height: 1.4;
}

.tracker-container .location-details p:last-child {
  margin-bottom: 0;
}

.tracker-container .location-details strong {
  color: var(--text-primary);
}

/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 768px) {
  .tracker-container .tracker-header {
    padding: 10px 16px;
    height: 50px;
  }

  .tracker-container .logo img {
    height: 30px;
  }

  .tracker-container .logo h2 {
    font-size: 1.4em;
  }

  .tracker-container .status-text {
    font-size: 12px;
    padding: 4px 10px;
  }

  .tracker-container .info-overlay {
    padding: 12px;
  }

  .tracker-container .cards-container {
    grid-template-columns: repeat(2, 1fr); /* Changed to 2 columns */
    padding: 16px;
    gap: 10px;
    border-radius: 16px;
  }

  /* Make ETA card span full width */
  .tracker-container .info-card.eta {
    grid-column: 1 / -1; /* Span both columns */
    padding: 12px 16px; /* Slightly larger padding for emphasis */
  }

  .tracker-container .info-card {
    padding: 10px 12px;
    gap: 4px;
    border-radius: 12px;
  }

  .tracker-container .info-card h2 {
    font-size: 11px;
    letter-spacing: 0.5px;
  }

  .tracker-container .info-card p {
    font-size: 16px; /* Slightly larger for better readability */
  }

  /* Adjusted ETA text size */
  .tracker-container .info-card.eta p {
    font-size: 18px; /* Larger size for emphasis */
  }

  .tracker-container .support-button {
    top: 100px; /* Adjusted to account for new layout */
    bottom: auto;
    right: 18%;
    transform: translateX(50%);
    padding: 7px 13px;
    font-size: 12px;
  }

  .tracker-container .support-button:hover {
    transform: translateX(50%) translateY(-2px);
  }

  .tracker-container .support-button:active {
    transform: translateX(50%) translateY(0);
  }

  .tracker-container .support-button svg {
    width: 14px;
    height: 14px;
  }

  .tracker-container .map-container {
    top: 50px;
    bottom: 130px; /* Adjusted for new metrics layout */
    height: calc(100vh - 180px) !important;
  }

  .tracker-container .info-overlay {
    background: linear-gradient(
      180deg,
      rgba(18, 18, 18, 0) 0%,
      rgba(18, 18, 18, 0.95) 30%,
      rgba(18, 18, 18, 1) 100%
    );
    padding-bottom: 16px;
  }
  .tracker-container .rider-info {
    bottom: 200px;
    padding: 12px 16px;
    width: calc(100% - 40px);
    margin: 0 8px;
    border-radius: 12px;
    align-items: center;
    justify-content: space-evenly;
  }

  .tracker-container .rider-avatar {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .tracker-container .rider-name {
    font-size: 12px;
  }

  .tracker-container .rider-status {
    font-size: 10px;
  }

  .tracker-container .call-rider {
    padding: 6px 12px;
    font-size: 12px;
  }

  .tracker-container .call-rider svg {
    width: 14px;
    height: 14px;
  }

  .tracker-container .admin-info {
    top: auto;
    right: 0;
    left: 0;
    bottom: 200px;
    width: auto;
    max-height: 50vh;
    padding: 16px;
  }

  .tracker-container .admin-card {
    padding: 12px;
  }

  .tracker-container .location-image {
    height: 150px;
  }
}
