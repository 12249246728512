.document-card * {
  font-family: "Satoshi-Bold", sans-serif;
}

.document-card {
  background-color: #e54c4c;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
  width: 60%;
  align-items: center;
  /* margin-left: 230px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.document-text {
  color: white;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.document-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.document-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.field-label {
  font-weight: 500;
}

.view-link, .approve-button {
  background-color: white;
  color: #e54c4c; /* Theme color */
  border: 2px solid #e54c4c; /* Theme color */
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 80px;
}

.view-link:hover, .approve-button:hover {
  background-color: #e54c4c; /* Theme color */
  color: white;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.null-value {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  font-size: 0.9rem;
}