.multidrop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 100px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 70vh; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Adjusted height */
  object-fit: cover; /* Cover the entire container */
  z-index: -1; /* Ensure it stays in the background */
  filter: brightness(0.45); /* Adjust brightness */
}
.left {
  width: 60%; /* Adjust based on preferred width */
  text-align: center; /* Center content if preferred */
}
.multidrop-content {
  z-index: 1; /* Higher than video to stay on top */
  padding: 40px;
  width: 100%; /* Adjust width as per design requirement */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.use-case-box {
  background: #ff416c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 10px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-width: 200px;
}

.use-case-title {
  color: white;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
}

.multidrop-content h1 {
  font-size: 2.5em;
  color: white;
  font-weight: bolder;
}
.multidrop-content h2 {
  font-size: 1.5em;
  color: white;
  font-family: "Poppins-Light", sans-serif;
}

.highlight {
  color: var(--primary-color);
}

.multidrop-info {
  padding: 20px;
  text-align: left; /* Align text to the left */
  font-family: "Poppins", sans-serif;
  width: 80%; /* Adjust width as needed */
}

.multidrop-info h2 {
  font-size: 2em;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.multidrop-info p {
  font-size: 1.2em;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}
/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .video-wrapper {
    height: 50vh; /* Adjust height for mobile view */
  }

  .multidrop-content {
    padding: 20px;
  }

  .multidrop-content h1 {
    font-size: 1.8em; /* Adjust font size for mobile view */
  }

  .multidrop-content h2 {
    font-size: 1.2em; /* Adjust font size for mobile view */
  }

  .left {
    width: 90%; /* Adjust width for mobile view */
  }

  .use-case-box {
    max-width: 150px; /* Adjust width for mobile view */
  }
}
