.mainContent {
  width: 100%;
  margin-left: 250px;
}

.adminWrapper {
  display: flex;
  min-height: 100vh;
}

.document-viewer {
  display: flex;
}

.document-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.document-image {
  max-width: 40%;
  max-height: 100%;
}

.pdf-viewer {
  width: 100%;
  max-width: 800px; /* Set a max width for the PDF viewer */
  border: 1px solid #e54c4c; /* Theme color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rider-info {
  background-color: #f9f9f9;
  border: 1px solid #e54c4c; /* Theme color */
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  text-align: left;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rider-info p {
  margin: 0;
  padding: 5px 0;
  font-size: 1rem;
  color: #333;
}

.rider-info strong {
  color: #e54c4c; /* Theme color */
}

.back-button {
  background-color: #e54c4c; /* Theme color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  position: absolute; /* Add this */
  top: 20px; /* Adjust as needed */
  left: 280px; /* Adjust as needed */
}

.back-button:hover {
  background-color: #c43b3b; /* Darker theme color */
}

.mainContent {
  flex-grow: 1;
  padding: 2rem;
  background-color: #f5f5f5;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.order-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.order-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.order-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.order-details p {
  margin: 0;
  color: #666;
  font-size: 0.95rem;
}

.order-details strong {
  color: #333;
  font-weight: 500;
  margin-right: 0.5rem;
}

.rider-details {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.rider-details p {
  color: #e54c4c;
  font-weight: 500;
}

.rider-details strong {
  color: #333;
  font-weight: 600;
}

.potential-riders {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px dashed #eee;
}

.potential-riders h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.potential-rider {
  background: #f8f8f8;
  border-radius: 6px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}

.potential-rider:last-child {
  margin-bottom: 0;
}

.potential-rider p {
  color: #666;
  font-size: 0.9rem;
  margin: 0.25rem 0;
}

.potential-rider strong {
  color: #333;
  font-weight: 500;
  margin-right: 0.5rem;
}

.active-orders-button {
  background-color: #e54c4c;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-bottom: 2rem;
}

.active-orders-button:hover {
  background-color: #c43b3b;
  transform: translateY(-1px);
}

.active-orders-button:active {
  transform: translateY(0);
}

.unassigned-riders {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px dashed #eee;
}

.unassigned-riders h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.unassigned-rider {
  background: #fff5f5;
  border-radius: 6px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border: 1px solid #ffe5e5;
}

.unassigned-rider:last-child {
  margin-bottom: 0;
}

.unassigned-rider p {
  color: #666;
  font-size: 0.9rem;
  margin: 0.25rem 0;
}

.unassigned-rider strong {
  color: #333;
  font-weight: 500;
  margin-right: 0.5rem;
}

/* Update existing sections with headers */
.rider-details h4,
.potential-riders h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.phone-link {
  color: #e54c4c;
  text-decoration: none;
  transition: color 0.2s ease;
  cursor: pointer;
}

.phone-link:hover {
  color: #c43b3b;
  text-decoration: underline;
}

/* Update the existing styles to accommodate the phone link */
.rider-details p .phone-link,
.potential-rider p .phone-link,
.unassigned-rider p .phone-link {
  color: #e54c4c;
  font-weight: 500;
}

.fee-details {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8fff8;
  border-radius: 6px;
  border: 1px solid #e0f0e0;
}

.fee-details h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.fee-details p {
  color: #666;
  font-size: 0.95rem;
  margin: 0.25rem 0;
}

.fee-details strong {
  color: #333;
  font-weight: 500;
  margin-right: 0.5rem;
}

.fee-details > p {
  color: #2c7a2c;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
}

.discount-tag {
  background-color: #e54c4c;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-left: 8px;
}

.discounted-price {
  color: #e54c4c !important;
  font-size: 1rem !important;
  margin-bottom: 0.75rem;
}

.fee-breakdown {
  padding-left: 1rem;
  border-left: 2px solid #e0f0e0;
  margin-bottom: 1rem;
}

.fee-breakdown p {
  color: #666;
  font-size: 0.9rem;
}

.rider-fee {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed #e0f0e0;
}

.rider-fee h4 {
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

.rider-fee .fee-breakdown {
  background: #fff;
  margin-top: 0.5rem;
}

.fee-input {
  width: 80px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-left: 4px;
}

.fee-actions {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e0f0e0;
  display: flex;
  gap: 0.5rem;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.edit-button {
  background-color: #e54c4c;
  color: white;
}

.edit-button:hover {
  background-color: #c43b3b;
  transform: translateY(-1px);
}

.save-button {
  background-color: #2c7a2c;
  color: white;
}

.save-button:hover {
  background-color: #236023;
  transform: translateY(-1px);
}

.cancel-button {
  background-color: #666;
  color: white;
}

.cancel-button:hover {
  background-color: #555;
  transform: translateY(-1px);
}

.total-rider-fee {
  color: #2c7a2c !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  margin-bottom: 0.75rem !important;
}

.rider-fee-breakdown {
  padding-left: 1rem;
  border-left: 2px solid #e0f0e0;
}

.rider-fee-breakdown p {
  color: #666;
  font-size: 0.9rem;
  margin: 0.25rem 0;
}

.special-instructions {
  margin: 0.5rem 0;
  padding: 0.75rem;
  background: #fff5e6;
  border-radius: 6px;
  border: 1px solid #ffe0b2;
}

.special-instructions p {
  color: #664400;
  font-size: 0.95rem;
  margin: 0;
}

.special-instructions strong {
  color: #664400;
  font-weight: 600;
}

.user-details {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background: #f8f8f8;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.user-details h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.user-details p {
  color: #666;
  font-size: 0.95rem;
  margin: 0.25rem 0;
}

.user-details strong {
  color: #333;
  font-weight: 500;
  margin-right: 0.5rem;
}

.user-details .phone-link {
  color: #e54c4c;
  text-decoration: none;
  transition: color 0.2s ease;
}

.user-details .phone-link:hover {
  color: #c43b3b;
  text-decoration: underline;
}

.map-link {
  display: block;
  text-align: center;
  margin: 8px auto;
  color: #e54c4c;
  text-decoration: none;
  transition: color 0.2s ease;
  width: fit-content;
}

.map-link:hover {
  color: #c43b3b;
}

.map-icon {
  font-size: 1.4rem;
  vertical-align: middle;
}

.order-link {
  color: #e54c4c;
  text-decoration: none;
  transition: color 0.2s ease;
}

.order-link:hover {
  color: #c43b3b;
  text-decoration: underline;
}

.unassign-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-top: 0.75rem;
  width: 100%;
}

.unassign-button:hover {
  background-color: #cc0000;
  transform: translateY(-1px);
}

.unassign-button:active {
  transform: translateY(0);
}

.no-rider {
  background: #f8f8f8;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #eee;
}

.no-rider-message {
  color: #666 !important;
  font-style: italic;
  text-align: center;
  margin: 0.5rem 0 !important;
  font-weight: normal !important;
}

.profit-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px dashed #e0f0e0;
}

.profit-section h4 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.profit-breakdown {
  background: #f8f8f8;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.profit-amount,
.profit-percentage {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0.25rem 0;
}

.profit-positive {
  color: #2c7a2c;
}

.profit-negative {
  color: #e54c4c;
}

.profit-toggle {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
}

.profit-toggle:hover {
  opacity: 0.8;
}

.profit-toggle h4 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toggle-icon {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #666;
}

.profit-breakdown {
  margin-top: 0.75rem;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.admin-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-container h1 {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.input-group input:focus {
  outline: none;
  border-color: #e54c4c;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #e54c4c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.login-button:hover {
  background-color: #c43b3b;
}

.login-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #e54c4c;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.location-details {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(229, 76, 76, 0.05);
  border-radius: 8px;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.location-details p {
  margin: 5px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.location-input {
  width: 120px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.location-input:focus {
  outline: none;
  border-color: #e54c4c;
}

.edit-location-button,
.save-location-button,
.cancel-location-button {
  padding: 4px 12px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.edit-location-button {
  background-color: #e54c4c;
  color: white;
}

.edit-location-button:hover {
  background-color: #d43c3c;
  transform: translateY(-1px);
}

.save-location-button {
  background-color: #2c7a2c;
  color: white;
}

.save-location-button:hover {
  background-color: #236023;
  transform: translateY(-1px);
}

.cancel-location-button {
  background-color: #666;
  color: white;
  margin-top: 8px;
  width: 100%;
}

.cancel-location-button:hover {
  background-color: #555;
  transform: translateY(-1px);
}

.view-map-link {
  display: inline-block;
  margin-top: 8px;
  padding: 6px 12px;
  background-color: #e54c4c;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s;
  width: 100%;
  text-align: center;
}

.view-map-link:hover {
  background-color: #d43c3c;
}

/* Mobile Styles for Active Orders */
@media screen and (max-width: 768px) {
  .mainContent {
    margin-left: 0;
    padding: 1rem;
    margin-top: 70px; /* Fixed height for the header area */
    position: relative;
    z-index: 1;
  }

  /* Add padding for the title to avoid overlap with hamburger menu */
  .mainContent h1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px; /* Fixed height to match margin-top */
    display: flex;
    align-items: center;
    padding-left: 60px;
    font-size: 1.5rem;
    margin: 0;
    background: #f5f5f5;
    z-index: 99;
    border-bottom: 1px solid #e5e7eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  /* Adjust hamburger menu position */
  .mobile-menu-toggle {
    position: fixed;
    top: 1.2rem;
    left: 1rem;
    z-index: 100;
    padding: 0.5rem;
    background: transparent;
    border-radius: 4px;
  }

  .orders-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .order-card {
    padding: 1rem;
    width: 100%;
    margin: 0; /* Remove margin to use grid gap instead */
    position: relative;
    z-index: 1;
  }

  .order-card h3 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .order-details {
    gap: 0.5rem;
  }

  .order-details p {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .fee-details,
  .rider-details,
  .potential-riders,
  .unassigned-riders {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .fee-breakdown,
  .rider-fee-breakdown {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .fee-input {
    width: 100%;
    max-width: 120px;
  }

  .fee-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .edit-button,
  .save-button,
  .cancel-button,
  .unassign-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .potential-rider,
  .unassigned-rider {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .location-details {
    margin-top: 0.75rem;
  }

  .location-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .location-input {
    width: 100%;
  }

  .profit-section {
    margin-top: 1rem;
  }

  .profit-breakdown {
    padding: 0.75rem;
  }

  .special-instructions {
    margin-top: 0.75rem;
    padding: 0.75rem;
  }

  .user-details {
    margin-bottom: 0.75rem;
  }

  /* Improve touch targets for mobile */
  .nav-item,
  .phone-link,
  .map-link,
  button {
    min-height: 44px;
    display: flex;
    align-items: center;
  }

  /* Make the overlay cover the full screen */
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
