@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.waitlistSection {
  background: #ffffff;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  width: 80%;
  max-width: 600px;
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.waitlistForm {
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}

.divider {
  margin: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.line {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px 0;
}

.rider-link a {
  color: #d9534f;
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  transition: color 0.3s;
  padding: 0 10px;
  font-weight: 600;
}

.rider-link a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rider-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waitlistForm h2 {
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
}

.formInput {
  margin-bottom: 15px;
  padding: 12px 20px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 18px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.formInput:focus {
  border-color: #d9534f;
  outline: none;
  box-shadow: 0 0 0 4px rgba(217, 83, 79, 0.3);
}

.primary-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  letter-spacing: 0.5px;
  transition: background-color 0.3s, transform 0.2s;
}

.primary-button:hover {
  background-color: #bf4040;
  transform: translateY(-2px);
}

.primary-button.loading {
  background-color: #aaa; /* Gray color indicating loading */
  cursor: not-allowed;
}

.errorMessage,
.successMessage {
  color: #bf4040;
  text-align: center;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; /* Above everything else */
}

.success-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.success-icon {
  color: #28a745; /* Success green color */
  font-size: 50px;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .waitlistSection {
    width: 95%; /* More width for smaller screens */
    padding: 30px; /* Less padding on small devices */
  }

  .waitlistForm h2 {
    font-size: 28px; /* Slightly smaller title on small screens */
  }

  .formInput,
  .primary-button {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
