.login-box {
  background: white;
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-box h2 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
}

.input-container,
.password-input-container {
  position: relative;
  margin-bottom: 1rem;
}

.input-container input,
.password-input-container input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.password-input-container input {
  padding-right: 4rem;
}

.toggle-password {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  transition: color 0.2s;
}

.toggle-password:hover {
  color: #333;
}

input:focus {
  outline: none;
  border-color: #e54c4c;
  box-shadow: 0 0 0 2px rgba(229, 76, 76, 0.1);
}

button[type="submit"] {
  width: 100%;
  padding: 0.75rem;
  background-color: #e54c4c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

button[type="submit"]:hover {
  background-color: #c43b3b;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #e54c4c;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.875rem;
}

.setup-section {
  text-align: center;
}

.setup-section p {
  margin-bottom: 1.5rem;
  color: #666;
  font-size: 0.95rem;
}

.setup-button {
  background-color: #2c7a2c;
}

.setup-button:hover {
  background-color: #236023;
}

body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
