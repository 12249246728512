@tailwind base;
@tailwind components;
@tailwind utilities;

/* Pattern background utility class */
.pattern-bg {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
    linear-gradient(-45deg, #f0f0f0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #f0f0f0 75%),
    linear-gradient(-45deg, transparent 75%, #f0f0f0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

@font-face {
  font-family: "Satoshi-Variable";
  src: url("../fonts/Satoshi-Variable.woff2") format("woff2"),
    url("../fonts/Satoshi-Variable.woff") format("woff"),
    url("../fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary-color: #e54c4c;
  --secondary-color: #140000;
}

/* In your global.css or similar */
@layer base {
  h1 {
    @apply text-4xl md:text-5xl lg:text-7xl;
    line-height: 1.2;
  }

  .hero-text {
    @apply text-3xl md:text-4xl lg:text-5xl;
    line-height: 1.3;
  }
}
