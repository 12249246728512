.adminWrapper {
    display: flex;
}

.mainContent {
  flex: 1;
  padding: 20px;
}

.generate-button {
  background-color: #e54c4c; /* Theme color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #c43b3b; /* Darker theme color */
}

.notifications-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}

/* First row takes full width */
.notifications-grid > *:nth-child(-n+3) {
    flex: 1 1 30%; /* Allow grow, shrink, and base width */
}

/* Bottom row items */
.notifications-grid > *:nth-child(n+4) {
    flex: 0 0 30%; /* Don't grow or shrink, fixed width */
}

.notification-form {
    margin-top: 40px;
    padding: 30px;
    background-color: #f9f9f9;
    border: 1px solid #e54c4c;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-family: "Poppins", sans-serif;
    display: block;
    margin-bottom: 10px;
    color: #e54c4c;
    font-weight: 500;
    font-size: 1rem;
  }

.form-group input,
.form-group textarea {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #e54c4c;
    box-shadow: 0 0 0 2px rgba(229, 76, 76, 0.1);
}

.form-group textarea {
    height: 120px;
    resize: vertical;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: #999;
    font-style: italic;
}

.submit-button {
    background-color: #e54c4c; /* Theme color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }