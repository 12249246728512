.sidebar {
  height: 100vh;
  width: 250px;
  background-color: #f8f9fa;
  border-right: 1px solid #e5e7eb;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  padding: 1rem;
  margin: -1rem -1rem 1rem -1rem;
  width: calc(100% + 2rem);
  left: 0;
}

.logoo {
  width: 40px;
  height: auto;
}

.sidebar-title {
  font-size: 40px;
  font-weight: bold;
  color: #e54c4c;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 1;
}

.nav-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 30px;
  margin-top: 20px;
  border-radius: 0.5rem;
  color: #e54c4c;
  text-decoration: none;
  transition: background-color 0.2s;
  width: 100%;
  font-family: "Satoshi-Bold", sans-serif;
  text-align: left;
  font-size: 17px;
}

.nav-icon {
  font-size: 1.25rem;
  min-width: 24px;
  text-align: center;
}

.nav-item:hover {
  background-color: #e7e8ea;
}

.nav-item.active {
  background-color: #e54c4c;
  color: #f8f9fa;
}

.logout-button {
  font-family: "Satoshi-Bold", sans-serif;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 30px;
  color: #dc2626;
  text-decoration: none;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  width: 100%;
  margin-top: auto;
  background: none;
  border: none;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #fef2f2;
}

/* Mobile Menu Toggle Button */
.mobile-menu-toggle {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1001;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.hamburger-icon {
  width: 24px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e54c4c;
  transition: all 0.3s ease-in-out;
}

.close-sidebar {
  display: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  color: #e54c4c;
  cursor: pointer;
  padding: 0.5rem;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    width: 80%;
    max-width: 300px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .mobile-menu-toggle {
    display: block;
  }

  .close-sidebar {
    display: block;
  }

  .sidebar-overlay {
    display: block;
  }

  .logo-container {
    justify-content: center;
    position: relative;
  }
}

/* Adjust main content margin for mobile */
@media screen and (max-width: 768px) {
  .mainContent {
    margin-left: 0;
    padding: 1rem;
  }
}
